@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto Slab", sans-serif;
    color: #fff;
}

#root {
    background: #151515;
}

.app {
    height: 100vh;
    max-width: 500px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.page {
    flex-direction: column;
    height: 100%;
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding: 30px 16px;
}

.page__content {
    height: 100%;
    display: flex;
    gap: 30px;
    flex-direction: column;
    position: relative;
}

.home {

}

.home__top {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}

.stat {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.info__balance {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.info__balance img {
    width: 48px;
    height: 48px;
    display: block;
}

.info__balance p {
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
}

.info__energy {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.info__energy p {
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
}

.home__coin {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.05s all ease-in;
}

.home__bigcoin {
    width: 280px;
    height: 280px;
    display: block;
}

.home__coin.clicked {
    scale: 1.05;
}

.btn {
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;
    border-radius: 15px;
    background: #3160DC;
    cursor: pointer;
}

.home__booster {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.booster__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    gap: 10px;
    padding: 10px;
    background: rgba(255,255,255,.05);
    border-radius: 15px;
    width: 100%;
}

.booster__item-btn {
    padding: 15px 10px;
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    align-content: center;
    justify-content: center;
    line-height: 1;
}

.booster__item-btn img {
    width: 18px;
    height: 18px;
}

.booster__item-title {
    font-weight: 700;
    line-height: 1;
}

.booster__item-description {
    color: rgba(255,255,255,.5);
    font-size: 12px;
    text-align: center;
    line-height: 1.2;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 16px 32px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #151515;
}

.nav a {
    position: relative;
    text-decoration: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}

.nav p {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
}

.list__wrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.list__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
}

.list__items-wrap {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.list__item {
    background: rgba(255,255,255,.05);
    border-radius: 15px;
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.list__side {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.list__item-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
}

.list__item-description {
    font-size: 12px;
    color: rgba(255,255,255,.5);
    line-height: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.list__item-description img {
    width: 18px;
    height: 18px;
}

.list__item-btn {
    padding: 15px 10px;
    font-size: 16px;
    font-weight: 700;
    align-content: center;
    justify-content: center;
    line-height: 1;
}

.list__item-btn img {
    width: 18px;
    height: 18px;
}





@media screen and (max-width: 380px) {
    .app {
        padding: 0;
    }
    .secret {
        height: 100vh;
    }
    .secret__main input {
        width: 200px;
    }
}
@media screen and (max-height: 638px) {
    * {
        overflow-y: hidden;
    }
}/*# sourceMappingURL=main.css.map */
